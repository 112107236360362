import * as React from 'react';
import Layout from '../../components/layout';
import { graphql } from 'gatsby';
import { Header } from '../../components/organisms/Header';
import DataboutTools from '../../components/organisms/DataboutTools';
import NeedMore from '../../components/organisms/NeedMore';
import energetykaImage from '../../images/power-engineering.svg';
import NewestNews from '../../components/organisms/NewestNews';
import { AboutType } from './transport';
import SubpageAbout from '../../components/organisms/SubpageAbout';
import PageContent from '../../components/organisms/PageContent';
// import ProsAndConsTable, { ProsAndConsTableItem } from '../../components/organisms/ProsAndConsTable';
import innertext from 'innertext';
import SEO from '../../components/seo';
import { useI18next, useTranslation } from 'gatsby-plugin-react-i18next';
import { getPostModel } from '../../shared/wp-post-utils';
import { defaultLanguage } from '../../../site-config';



const Energetyka: React.FC<AboutType> = ({data, location}) => {
  const {t} = useTranslation();
  const {language} = useI18next();
  const LDAPHeaderUser = JSON.parse(data.parent.author.node.ldapUser)[0];

//   const PowerEngineeringProsAndCons: ProsAndConsTableItem[] = [
//     {
//         title: t('powerEngineeringProsAndCons.title1'),
//         pros: t('powerEngineeringProsAndCons.pros1'),
//         cons: t('powerEngineeringProsAndCons.cons1'),
//     },
//     {
//         title: t('powerEngineeringProsAndCons.title2'),
//         pros: t('powerEngineeringProsAndCons.pros2'),
//         cons: t('powerEngineeringProsAndCons.cons2'),
//     },
//     {
//         title: t('powerEngineeringProsAndCons.title3'),
//         pros: t('powerEngineeringProsAndCons.pros3'),
//         cons: t('powerEngineeringProsAndCons.cons3'),
//     },
// ]

    return ( 
        <Layout>
            <SEO title={data.parent.title} description={innertext(data.parent.content)}/>
            <Header 
                title={data.parent.title} 
                description={innertext(data.parent.content)}
                headerImage={energetykaImage}
                headerImageWidth={600}
				        isSignature={true}
                user={data.parent.author.node}
                signatureAuthor={LDAPHeaderUser.name}
                signaturePosition={LDAPHeaderUser.title}
                signatureDeskPhoneNumber={LDAPHeaderUser.telephoneNumber?.[0]}
                signaturePhoneNumber={LDAPHeaderUser.mobile?.[0]}
                signatureEmail={LDAPHeaderUser.mail}
                signatureCity={LDAPHeaderUser.physicalDeliveryOfficeName}
                signatureShareLink={location.href}
                SEOTitle={data.parent.title}
                SEODescription={innertext(data.parent.content)}
                isArrowNavVisible={true}
                firstHeaderNavLink="uslugi"
                firstHeaderNavLinkName={t('services.title')} 
            />
            <PageContent>
                <SubpageAbout data={data.children.nodes}/>
                {data.newestNews.nodes.length > 0 &&  language === defaultLanguage && <NewestNews posts={data.newestNews.nodes.map(getPostModel)}/>}
                <DataboutTools title="Databout.Tools" subTitle={t('toolsAd.subTitle')} description={t('toolsAd.description')} link="/tools"/>
                <NeedMore/>
            </PageContent>
        </Layout>

     );
}
 
export default Energetyka;


export const pageQuery = graphql`
query($language: String!) {
  parent: wpPage(title: {in: ["Energetyka", "Power engineering"]}, language: {slug: {eq: $language}}) {
		title
    content
    ...AuthorPage
  }
  children: allWpPage(filter: {wpParent: {node: {slug: {regex: "/energetyka/"}}}, language: {slug: {eq: $language}}}, sort: {fields: menuOrder}) {
		nodes {
      title
      content
    }
  }
	newestNews: allWpPost(
    sort: {fields: dateGmt, order: DESC}
    filter: {categories: {nodes: {elemMatch: {name: {eq: "News"}}}}, language: {slug: {eq: $language}}, tags: {nodes: {elemMatch: {name: {eq: "energetyka"}}}}}
    limit: 3
	) {
		nodes {
			...PageItems
		}
	}
  locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
}`